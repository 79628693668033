export const environment = {
  production: true,
  env: 'prod',
  use_hash_for_routing: false,
  version: '6.29.1',
  auth_client_id: '2_647eboqlg9ogwwccog0cc4w08sw44oggoogcsowg4cgc4ockoo',
  auth_client_secret: '2gkmgqea0pgko844oc80c4co0440wgo808gg0gcgwkooo8wcc0',
  auth_fb_grant_type: 'https://dev.ecni.fr/facebook',
  auth_google_grant_type: 'https://dev.ecni.fr/google',
  // adwordsKey: 'AW-980716637/8ae5CPuGpIkBEN2Y0tMD',
  adwordsKey: undefined,

  site_id: 'ecni',
  site_name: 'EDN',
  defaultLanguage: 'fr',
  locale: 'fr-FR',
  localeApple: 'fr_FR',
  api_base_url: 'https://api.edn.fr/api',
  maintenance_endpoint:
    'https://hueg7rfcof.execute-api.eu-west-1.amazonaws.com/release/ecni-prod-maintenance',
  stripe_api_key: 'pk_live_sZKVlzGQFhTVwJp7vAaOKonS',
  google_web_id: '712294402346-0kbo2dldtkfeanibkgaii3dnd70i3no3.apps.googleusercontent.com',
  pusher_id: '67a54a11c90a6f15336a',
  fb_id: '1415786885322762',
  google_play_id: 'fr.ecni.app',
  appstore_id: 'id915067389',
  appstore_service_id: 'fr.ecni.service',
  apple_redirect_uri: 'https://www.edn.fr',
  pushChannel: 'ecni_push_channel',
  pushName: 'EDN',
  intercomAppId: 'kq47xsqo',
  intercomHelpdeskUrl: 'https://intercom.help/ecni',
  facebook_url: 'https://www.facebook.com/ECNi.fr',
  instagram_url: 'https://www.instagram.com/edn__fr/',
  tiktok_url: 'https://www.tiktok.com/@edn.fr',
  telegram_url: '',
  admin_url: 'https://admin.edn.fr',
  domain: 'edn.fr',
};
